import React from "react";

import imgLB from '../../assets/image/svg/parent.svg';
import imgLW from '../../assets/image/svg/parent.svg';

const FooterDefault = ({ className, ...rest }) => {
  return (
      <div
        className={`pt-8 pb-6 ${className}`}
        {...rest}
        style={{ background: '#520260' }}
      >
        <div className="container">
          <div className="d-flex align-items-center justify-content-center justify-content-sm-between flex-column flex-sm-row flex-wrap">
            <div>
              <div className="brand-logo mb-5 mb-sm-0 mt-md-4 dark-mode-texts">
                <a href="https://www.akofisgroup.com">
                  <img
                    className="mx-auto mx-0 light-version-logo default-logo"
                    src={imgLB}
                    alt=""
                    style={{ height: 30 }}
                  />
                  <img
                    src={imgLW}
                    alt=""
                    className="dark-version-logo mx-auto mx-0"
                    style={{ height: 30 }}
                  />
                </a>
              </div>
            </div>
            <div>
              <ul className="d-flex align-items-center list-unstyled mb-0 mr-0">
                <li>
                  <a
                    href="https://web.facebook.com/ocoriegh/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pl-5 text-white gr-hover-text-dodger-blue-1 font-size-5 pr-6"
                  >
                    <i className="fab fa-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/ocoriegh"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pl-5 text-white gr-hover-text-dodger-blue-1 font-size-5 pr-6"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/ocoriegh"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pl-5 text-white gr-hover-text-dodger-blue-1 font-size-5 pr-6"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/ocoriegh"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pl-5 text-white gr-hover-text-dodger-blue-1 font-size-5 pr-6"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
};

export default FooterDefault;
